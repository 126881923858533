<template>
  <div class="header-info-container">
    <div class="flex-container">
      <el-button
        icon="el-icon-arrow-left"
        id="return-button"
        @click="handleReturn()"
      />
      <div class="info-container">
        <span>
          {{ provider }}
        </span>
        <span class="info-container-date"
          >Cadastre e gerencie participantes.</span
        >
      </div>
    </div>
    <div class="flex-container">
      <el-input
        class="search-input"
        v-model="search"
        size="medium"
        placeholder="Pesquisar na tabela"
        clearable
        prefix-icon="el-icon-search"
        id="search-input"
      />
      <el-button class="primary-button" @click="handleButtonClick()">
        {{ btnLabel }}
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EntriesHeader',
  props: {
    entriesValue: {
      type: Number,
      default: () => 0,
    },
    rubricType: String,
    provider: String,
  },
  data() {
    return {
      search: '',
      btnLabel: '',
    }
  },
  methods: {
    handleReturn() {
      this.$router.go(-1)
    },
    handleButtonClick() {
      this.$emit('onAddButtonClick')
    },
  },
  watch: {
    search() {
      this.$emit('onSearchChange', this.search)
    },
  },
  created() {
    const labels = {
      leasing: 'Adicionar Leasing',
      training: 'Adicionar Participante',
      external: 'Adicionar Colaborador',
    }
    this.btnLabel = labels[this.rubricType]
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
