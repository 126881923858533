import api from '../instance'

export function getEntriesInformations(
  requestIdType,
  providerId,
  rfId,
  rubric
) {
  if (requestIdType === 'correlate') {
    return api.get(
      `/entry?correlateId=${providerId}&financialReportId=${rfId}&rubric=${rubric}`
    )
  } else {
    return api.get(
      `/entry?ledgerBookId=${providerId}&financialReportId=${rfId}&rubric=${rubric}`
    )
  }
}

export function insertEntrie(
  requestIdType,
  objData,
  providerId,
  costcenterCode,
  rubric
) {
  if (requestIdType !== 'ledgerBook') {
    return api.post(`/entry`, {
      ...objData,
      rubric: rubric,
      costCenterCode: costcenterCode,
      value: Number(objData.value),
      hours: Number(objData.hours),
      correlateId: providerId,
    })
  } else {
    return api.post(`/entry`, {
      ...objData,
      rubric: rubric,
      costCenterCode: costcenterCode,
      value: Number(objData.value),
      hours: Number(objData.hours),
      ledgerBookId: providerId,
    })
  }
}

export function removeEntrie({ idList, rubric }) {
  return api.delete(`/entry`, { data: { idList, rubric } })
}

export function updateEntrie({ details, idList, rubric }, requestsType, id) {
  const data = { data: { ...details }, idList, rubric }
  requestsType !== 'ledgerBook'
    ? (data.data['correlateId'] = id)
    : (data.data['ledgerBookId'] = id)

  return api.patch(`/entry`, data)
}

export function entryAvaliable(requestIdType, providerId) {
  if (requestIdType === 'correlate') {
    return api.get(`/entryAvaliable?correlateId=${providerId}`)
  } else {
    return api.get(`/entryAvaliable?ledgerBookId=${providerId}`)
  }
}
