<template>
  <el-drawer
    class="drawer"
    :title="drawerName"
    :visible.sync="visibility"
    :size="570"
    direction="rtl"
    :before-close="onClose"
    :wrapperClosable="false"
  >
    <el-main class="content-container">
      <slot></slot>
      <div class="buttons-container">
        <el-button class="secondary-button" @click="onClose">
          Cancelar
        </el-button>
        <el-button
          class="primary-button"
          @click="onSave"
          :disabled="isBtnDisabled"
          >{{ btnLabel }}</el-button
        >
      </div>
    </el-main>
  </el-drawer>
</template>

<script>
export default {
  name: 'Drawer',
  data() {
    return {
      loading: false,
    }
  },
  props: {
    drawerName: String,
    visibility: Boolean,
    btnLabel: {
      default: 'Salvar',
      type: String,
    },
    isBtnDisabled: {
      default: false,
      type: Boolean,
    },
  },
  methods: {
    onClose() {
      this.$emit('close')
    },
    onSave() {
      this.$emit('save')
    },
  },
  computed: {
    getVisibility() {
      return this.visibility
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
