<template>
  <div>
    <EntriesHeader
      :provider="getHeaderText"
      :entriesValue="entriesValue"
      :rubricType="rubricType"
      @onSearchChange="setGeneralSearch"
      @onAddButtonClick="handleAddEntrie()"
    />
    <el-row class="header-container" v-if="rubricType === 'external'">
      <span class="entries-value"
        >Valor disponível para lançamentos:
        <b>{{ handleFormatValue(entriesValue) }}</b></span
      >
    </el-row>
    <Drawer
      :drawerName="editMode ? 'Editar participante' : 'Adicionar participante'"
      :visibility="showDrawer"
      @close="closeDrawer"
      @save="handleSave"
      :btnLabel="editMode ? 'Atualizar' : 'Salvar'"
    >
      <div class="drawer-container" v-if="rubricType === 'external'">
        <el-row>
          <span class="entries-value"
            ><b>Informações: </b> <br />
            {{ getInformations }}</span
          >
        </el-row>
        <el-row class="drawer-header">
          <span class="entries-value"
            >Valor disponível para lançamento:
            <b>{{ handleFormatValue(entriesValue) }}</b></span
          >
        </el-row>
        <el-row class="input-container">
          <span>Valor a ser lançado:</span>
          <money
            class="my-margin el-input__inner"
            v-bind="money"
            v-model="entriesInformations.value"
            ><template slot="prepend">R$</template></money
          >
        </el-row>
        <el-row class="input-container">
          <div>Nome:</div>
          <el-autocomplete
            class="my-margin"
            v-model="entriesInformations.name"
            placeholder="Selecione um colaborador "
            :fetch-suggestions="querySearch"
            @select="handleChangeColaborator"
          />
        </el-row>
        <el-row class="input-container">
          <span>CPF/CNPJ:</span>
          <el-input
            class="my-margin"
            v-model="entriesInformations.register"
            placeholder="Adicione o registro do colaborador"
            v-mask="mask"
            @input="handleRegisterMask"
          />
        </el-row>
        <el-row class="input-container">
          <span>Função do colaborador:</span>
          <el-input
            class="my-margin"
            v-model="entriesInformations.role"
            placeholder="Relate a função do colaborador"
          />
        </el-row>
        <el-row class="input-container">
          <span>Horas</span>
          <el-input
            class="my-margin"
            type="number"
            :min="0"
            v-model="entriesInformations.hours"
          />
        </el-row>
        <el-row class="input-container">
          <span>Nível de escolaridade:</span>
          <el-select
            class="my-margin"
            v-model="entriesInformations.schooling"
            placeholder="Selecione o nível de escolaridade"
          >
            <el-option
              v-for="(item, index) in schoolingOptions"
              :key="`schooling-${item}-${index}-option`"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-row>
        <el-row class="input-container">
          <span>Formação:</span>
          <el-input
            class="my-margin"
            v-model="entriesInformations.formation"
            placeholder="Relate a formação do colaborador"
          />
        </el-row>
        <el-row class="input-container" v-if="isExternal">
          <span>Tipo:</span>
          <el-select
            class="my-margin"
            v-model="entriesInformations.type"
            placeholder="Selecione um tipo"
          >
            <el-option
              v-for="(item, index) in typeOptions"
              :key="`type-${item.value}-${index}-option`"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-row>
        <el-row class="input-container">
          <span>Atividade:</span>
          <el-input
            class="my-margin"
            type="textarea"
            maxlength="1000"
            max="1000"
            :rows="5"
            show-word-limit
            placeholder="Relate a atividade do colaborador"
            v-model="entriesInformations.activity"
          />
        </el-row>
      </div>
      <div class="drawer-container" v-else>
        <el-row>
          <span class="entries-value"
            ><b>Informações: </b> {{ getInformations }}</span
          >
        </el-row>
        <el-row class="drawer-header"></el-row>
        <el-row class="input-container">
          <div>Nome:</div>
          <el-autocomplete
            class="my-margin"
            v-model="entriesInformations.name"
            :fetch-suggestions="querySearch"
            placeholder="Selecione um nome"
            @select="handleChangeColaboratorTraining"
          />
        </el-row>
        <el-row class="input-container">
          <span>CPF/CNPJ:</span>
          <el-input
            class="my-margin"
            v-model="entriesInformations.register"
            placeholder="Adicione um CPF/CNPJ"
            v-mask="mask"
            @input="handleRegisterMask"
          />
        </el-row>
      </div>
    </Drawer>
    <Drawer
      :drawerName="editMode ? 'Editar lançamento' : 'Adicionar Lançamento'"
      :visibility="showDrawerLeasing"
      @close="closeDrawer"
      @save="handleSave"
      :btnLabel="editMode ? 'Atualizar' : 'Salvar'"
    >
      <div class="drawer-container">
        <el-row>
          <span class="entries-value"
            ><b>Informações: </b> <br />
            {{ getLeasingInformations }}</span
          >
        </el-row>
        <el-row class="drawer-header">
          <span class="entries-value"
            >Valor disponível para lançamento:
            <b>{{ handleFormatValue(entriesValue) }}</b></span
          >
        </el-row>
        <el-row class="input-container">
          <span>Valor a ser lançado:</span>
          <money
            class="my-margin el-input__inner"
            v-bind="money"
            v-model="entriesInformations.value"
            ><template slot="prepend">R$</template></money
          >
        </el-row>
        <el-row class="input-container__date">
          <span>Data aquisição:</span>
          <el-date-picker
            class="my-margin"
            v-model="entriesInformations.acquisitionDate"
            type="date"
            placeholder="Selecione uma data"
            format="dd/MM/yyyy"
          />
        </el-row>
        <el-row class="input-container">
          <span>Descrição:</span>
          <el-input
            class="my-margin"
            v-model="entriesInformations.description"
            placeholder="Adicione uma descrição"
            type="textarea"
            :maxlength="1000"
            :show-word-limit="true"
            :rows="5"
          />
        </el-row>
        <el-row class="input-container">
          <span>Quantidade:</span>
          <el-input-number
            class="my-margin"
            v-model="entriesInformations.quantity"
            controls-position="right"
            type="number"
            min="0"
            placeholder="Adicione a quantidade"
          />
        </el-row>
        <el-row class="input-container">
          <span>Número serial:</span>
          <el-input
            class="my-margin"
            v-model="entriesInformations.serial"
            placeholder="Adicione o númeor serial"
          />
        </el-row>
        <el-row class="input-container">
          <span>Número ativo:</span>
          <el-input
            class="my-margin"
            v-model="entriesInformations.activeNumber"
            placeholder="Adicione o númeor ativo"
          />
        </el-row>
        <el-row class="input-container">
          <span>Fornecedor:</span>
          <el-input
            class="my-margin"
            v-model="entriesInformations.provider"
            placeholder="Adicione o fornecedor"
          />
        </el-row>
        <el-row class="input-container">
          <span>Nota fiscal:</span>
          <el-input
            class="my-margin"
            v-model="entriesInformations.invoice"
            placeholder="Adicione a nota fiscal"
          />
        </el-row>
        <el-row class="input-container__date">
          <span>Data aquisição original:</span>
          <el-date-picker
            class="my-margin"
            v-model="entriesInformations.originalAcquisitionDate"
            type="date"
            placeholder="Selecione uma data"
            format="dd/MM/yyyy"
          />
        </el-row>
        <el-row class="input-container">
          <span>Parcela:</span>
          <el-input
            class="my-margin"
            v-model="entriesInformations.portion"
            placeholder="Adicione a parcela"
          />
        </el-row>
        <el-row class="input-container">
          <span>Justificativa:</span>
          <el-input
            class="my-margin"
            type="textarea"
            maxlength="1000"
            max="1000"
            :rows="5"
            show-word-limit
            v-model="entriesInformations.justification"
            placeholder="Adicione a justificativa..."
          />
        </el-row>
      </div>
    </Drawer>
    <router-view
      :informations="informations?.entries || []"
      :searchText="searchText"
      :type="rubricType"
      :loading="loading"
      @onEditClick="handleEditClick"
      @onRemoveClick="handleRemoveClick"
    />
  </div>
</template>

<script>
import EntriesHeader from '@/components/EntriesHeader/EntriesHeader.vue'
import Drawer from '@/components/Drawer/Drawer.vue'
import { Money } from 'v-money'
import dayjs from '@/utils/dayjs'

import {
  getEntriesInformations,
  insertEntrie,
  removeEntrie,
  updateEntrie,
} from '@/service/entries'

import { getCollaboratorsByCostCenterCode } from '@/service/collaborator'
import { fetchLegalPersons } from '@/service/legalPerson'
import { fetchSchooling } from '@/service/schooling'

export default {
  name: 'EntriesPage',
  components: {
    EntriesHeader,
    Drawer,
    Money,
  },
  data() {
    return {
      entriesValue: 0,
      showDrawer: false,
      showDrawerLeasing: false,
      loading: true,
      entriesInformations: {
        value: 0,
        name: '',
        register: '',
        role: '',
        hours: 0,
        schooling: '',
        formation: '',
        activity: '',
        type: '',
        id: '',
      },
      informations: {},
      searchText: '',
      editMode: false,
      currentRow: null,
      requestsType: '',
      rubricType: '',
      rfId: '',
      providerId: '',
      collaboratorsList: [],
      legalPersonList: [],
      isExternal: false,
      typeOptions: [
        {
          value: 'direct',
          label: 'Direto',
        },
        {
          value: 'indirect',
          label: 'Indireto',
        },
      ],
      schoolingOptions: [],
      money: {
        decimal: ',',
        thousands: '.',
        precision: 2,
        masked: false,
      },
      mask: '###.###.###-##',
    }
  },
  methods: {
    async handleSave() {
      const loading = this.$loading({
        lock: true,
        text: this.editMode
          ? 'Atualizando lançamento...'
          : 'Inserindo lançamento...',
        background: 'hsla(0,0%,100%,.9)',
      })
      try {
        if (!this.editMode) {
          let obj = {
            ...this.entriesInformations,
            register: this.entriesInformations.register?.replace(/\D/g, ''),
          }
          await insertEntrie(
            this.requestsType,
            obj,
            this.providerId,
            this.costcenterCode,
            this.rubricType
          )
          this.fetchInformations()
          this.informations.value = '0'

          this.showDrawer = false
          this.showDrawerLeasing = false
        } else {
          await updateEntrie(
            {
              idList: [this.entriesInformations.id],
              details: {
                ...this.entriesInformations,
                register: this.entriesInformations.register?.replace(/\D/g, ''),
              },
              rubric: this.rubricType,
            },
            this.requestsType,
            this.providerId
          )
          this.fetchInformations()
          this.informations.value = '0'
          this.showDrawer = false
          this.showDrawerLeasing = false
        }
      } catch (error) {
        this.$message({
          showClose: true,
          type: 'error',
          message: error.response?.data || 'Falha ao inserir lançamento.',
          duration: 5000,
        })
      } finally {
        this.fetchCollaborators()
        this.fetchLegalPerson()
        loading.close()
      }
    },
    async handleGetSchoolingOptions() {
      try {
        const { data } = await fetchSchooling()
        if (data) {
          this.schoolingOptions = data
        }
      } catch (error) {
        this.$message({
          showClose: true,
          type: 'error',
          message: error.response.data,
          duration: 5000,
        })
      }
    },
    async handleRemoveClick(row) {
      try {
        const { data } = await removeEntrie({
          idList: [row.id],
          rubric: this.rubricType,
        })
        this.showDrawer = false
        this.showDrawerLeasing = false
        this.$message({
          showClose: true,
          type: 'success',
          message: 'Lançamento deletado com sucesso!',
          duration: 5000,
        })
        this.fetchInformations()
      } catch (error) {
        this.$message({
          showClose: true,
          type: 'error',
          message: error.response.data || 'Falha ao remover lançamento.',
          duration: 5000,
        })
      }
    },
    handleAddEntrie() {
      this.editMode = false
      this.rubricType === 'leasing'
        ? (this.showDrawerLeasing = true)
        : (this.showDrawer = true)
      this.entriesInformations = {
        value: 0,
        name: '',
        register: '',
        role: '',
        hours: 0,
        schooling: '',
        formation: '',
        activity: '',
        type: '',
      }
    },
    async fetchInformations() {
      try {
        this.loading = true
        const { data } = await getEntriesInformations(
          this.requestsType,
          this.providerId,
          this.rfId,
          this.rubricType
        )
        this.informations = data
        this.entriesValue = data.availableValue
      } catch (error) {
        this.$message({
          showClose: true,
          type: 'error',
          message:
            error.response.data || 'Falha ao buscar informações de lançamento.',
          duration: 5000,
        })
      } finally {
        this.loading = false
      }
    },
    setGeneralSearch(text) {
      this.searchText = text
    },
    closeDrawer() {
      this.entriesInformations.value = 0
      this.showDrawer = false
      this.showDrawerLeasing = false
    },
    handleChangeColaborator({ register, role, schooling, formation }) {
      this.entriesInformations.register = this.handleFormatRegister(register)
      this.entriesInformations.role = role
      this.entriesInformations.schooling = schooling
      this.entriesInformations.formation = formation
    },
    handleChangeColaboratorTraining({
      cpf,
      register,
      legalPersonId,
      collaboratorId,
    }) {
      this.entriesInformations.register = this.handleFormatRegister(
        cpf ? cpf : register
      )
      this.entriesInformations.collaboratorId = collaboratorId
      this.entriesInformations.legalPersonId = legalPersonId
    },
    handleEditClick(row) {
      this.editMode = true
      this.entriesInformations = {
        ...row,
        register: this.handleFormatRegister(row.register),
      }
      this.rubricType === 'leasing'
        ? (this.showDrawerLeasing = true)
        : (this.showDrawer = true)
    },
    querySearch(queryString, cb) {
      const collaboratorsList = this.isExternal
        ? this.legalPersonList
        : this.handleSortByName(
            [...this.collaboratorsList, ...this.legalPersonList].filter(
              (collab) => collab?.name
            )
          )
      let results = queryString
        ? collaboratorsList.filter(this.createFilter(queryString))
        : collaboratorsList
      cb(results)
    },
    createFilter(queryString) {
      return (collaborator) => {
        return (
          collaborator.value
            .toLowerCase()
            .indexOf(queryString.toLowerCase()) === 0
        )
      }
    },
    async fetchCollaborators() {
      try {
        const { data } = await getCollaboratorsByCostCenterCode(
          this.costcenterCode
        )
        this.collaboratorsList = data.map((e) => {
          return { ...e, value: e.name, collaboratorId: e.id }
        })
      } catch (error) {}
    },
    async fetchLegalPerson() {
      try {
        const { data } = await fetchLegalPersons(this.costcenterCode)
        this.legalPersonList = data.map((e) => {
          return { ...e, value: e.name, legalPersonId: e.id }
        })
      } catch (error) {}
    },
    handleRegisterMask(register) {
      const clearRegister = register?.replace(/\D/g, '')

      this.entriesInformations.register =
        this.handleFormatInputRegister(clearRegister)

      this.mask =
        clearRegister?.length <= 11 ? '###.###.###-##' : '##.###.###/####-##'
    },
  },
  created() {
    this.requestsType = this.$route.params.paramIdType
    this.rubricType = this.$route.params.rubricType
    this.rfId = this.$route.params.projectId
    this.providerId = this.$route.params.providerId
    this.costcenterCode = this.$route.params.costcenterCode
    this.isExternal = this.$route.path.includes('external')
    this.fetchInformations()
    this.handleGetSchoolingOptions()
    this.fetchCollaborators()
    this.fetchLegalPerson()
  },
  computed: {
    getInformations() {
      return `Mês ${dayjs(this.informations.month)
        .locale('pt-br')
        .format('MM/YYYY')},
        ${
          this.isExternal
            ? `Prestador: ${this.handleHasInformation(this.informations.name)}`
            : `Fornecedor: ${this.handleHasInformation(
                this.informations.provider
              )}`
        }
        , Valor total: ${this.handleFormatValue(this.informations.value)}`
    },
    getLeasingInformations() {
      return `Mês ${dayjs(this.informations.month)
        .locale('pt-br')
        .format('MM/YYYY')}, Fornecedor: ${this.handleHasInformation(
        this.informations.provider
      )}, Valor total: ${this.handleFormatValue(
        this.informations.value
      )}, Descrição dos serviços: ${this.handleHasInformation(
        this.informations.description
      )}, N° da nota fiscal: ${this.handleHasInformation(
        this.informations.invoice
      )}`
    },
    getHeaderText() {
      const titleOptions = {
        leasing: 'Arrendamento/Leasing',
        external: `${
          this.informations.name?.length > 0
            ? this.informations.name
            : 'de item não detalhado'
        }`,
        training: `Fornecedor ${
          this.informations.provider?.length > 0
            ? this.informations.provider
            : 'de item não detalhado'
        }`,
      }
      return titleOptions[this.rubricType]
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
